import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';



const Subscribe = () => {

    const uid = localStorage.getItem("user");

    return (
        <div>

<Typography align='center' sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }} variant="h3" component="h3">
                                    Subscribe to RoboEdit</Typography>
                                    <Typography align='center' sx={{ width: '100%', flexShrink: 0 }} variant="paragraph"><br></br>
                                    Subscribe to RoboEdit in order to continue proofreading with AI.</Typography><br></br><br></br>
        
<div style={{height: '100px'}}></div>   
<stripe-pricing-table 
client-reference-id={uid}
pricing-table-id="prctbl_1PCoI92MdDdQEGfse6UTqtY9"
publishable-key="pk_live_51PBSoX2MdDdQEGfsDPHf3c7jbpcqWmangehuv79cIGzheYkE6KqRmOLHZfYsJFmmM6MBTgSk1hQfIBDr51e336Yk00srsVjbx7">
</stripe-pricing-table>                   
</div>
    )
}

export default Subscribe;
