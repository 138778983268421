import React, { useEffect, useState, useCallback } from 'react';
import { ColorRing } from 'react-loader-spinner'
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { collection, getDocs, doc, setDoc, getDoc, query, orderBy, onSnapshot } from "firebase/firestore";

import { onAuthStateChanged, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { db, storage, auth, functions } from "../utils/firebase";
import localforage from 'localforage';
import emptyShelf from '../assets/emptyshelf.png';
import { Button, Paper, Grid, Box, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/Add';
import FileUpload from '../FileUploader/FileUpload';
import ReactImageAppear from "react-image-appear"

import logo from '../assets/robotedit.png';
import './Books.css';

class Book {
    constructor(title, author, content) {
        this.title = title;
        this.author = author;
        this.content = content;
    }
}

const Books = () => {


    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState([true]);
    const [status, setStatus] = useState(false);
    const [plan, setPlan] = useState("");
    const [newOpenNewBookDialog, setOpenNewBookDialog] = useState(false)
    const [title, setTitle] = useState('')
    const [author, setAuthor] = useState('')
    const [bookError, setBookError] = useState(false);
    const [bookErrorText, setBookErrorText] = useState("");
    const maxTitleLength = 100;


    onAuthStateChanged(auth, async (user) => {
        if (user) {

        } else {
            navigate("/");
        }
    })

    const user = localStorage.getItem("user");
    const userEmail = localStorage.getItem("user");

    console.log(user)

    const signOutUser = () => {
        signOut(auth).then(() => {
            localStorage.setItem("user", "")
            localStorage.setItem("userEmail", "")
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }



    const location = useLocation();
    const navigate = useNavigate();

    const getBooks = async () => {
        const booksRef = query(collection(db, "users/" + user + "/books"));
        const booksArr = [];
        onSnapshot(booksRef, (snapshot) => {
            // Maps the documents and sets them to the `msgs` state.
            snapshot.forEach((doc) => {
                let data = doc.data();
                console.log(data);
                data["id"] = doc.id;
                // doc.data() is never undefined for query doc snapshots
                booksArr.push(data)
            })
            setOpenNewBookDialog(false)
            return setBooks(booksArr)
        })
    }

    const getStatus = async () => {
        const userRef = doc(db, "users/" + user);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            const status = docSnap.data().status;
            const plan = docSnap.data().plan;
            console.log(status)
            if (status === 'active') {
                setStatus(true)
                setPlan(plan)
            }
        } else {
            console.log("Something went wrong checking plan status.");
        }

    }

    const createBook = async (bookTitle, author) => {


        const colors = [
            'beige',
            'moccasin',
            'lightsteelblue',
            'lavender',
            'palegreen',
            'lightcyan'
        ]

        const coverColor = colors[Math.floor(Math.random() * colors.length)];

        console.log(coverColor)

        const timestamp = parseInt(Date.now() / 1000);
        let obj = {
            title: bookTitle,
            author: author,
            hasOcrScanned: false,
            timestamp: timestamp
        }
        let objString = JSON.stringify(obj);
        localforage.setItem(bookTitle, objString);
        await setDoc(doc(db, "users/" + user + "/books", bookTitle), {
            title: bookTitle,
            author: author,
            coverColor: coverColor || "",
            hasOcrScanned: false,
            characters: [""],
            timestamp: timestamp,
            bookLayout: {
                frontMatter: [],
                body: {
                    sections: [
                        { id: "0", name: "nemo", order: 0, chapters: [] }
                    ]
                },
                backMatter: [
                ]
            }
        }).then(
            getBooks()
        );
    }

    const goToContents = async (book) => {
        const docRef = doc(db, "users/" + user + "/books/" + book.title);
        console.log("users/" + user + "/books/" + book.title)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            navigate("/books/contents", {
                state: {
                    book: book
                },
            });
        } else {
            return alert("Something went wrong getting the contents.");
        }
    }


    // const goToContents = async (book) => {

    //     const docRef = doc(db, "users/" + user + "/books/" + book.title);
    //     console.log("users/" + user + "/books/" + book.title)
    //     const docSnap = await getDoc(docRef);
    //     if (docSnap.exists()) {
    //         let bookData = await localforage.getItem(book.title);
    //         let bookObj = JSON.parse(bookData);
    //         let localTimestamp = bookObj.timestamp;
    //         let onlineTimestamp = docSnap.data().timestamp;
    //         if (localTimestamp === onlineTimestamp) {
    //             alert("THEY MATCH")
    //             navigate("/books/contents", {
    //                 state: {
    //                     book: book
    //                 },
    //             });
    //             return true
    //         } else {
    //             alert("THEY DO NOT MATCH")
    //             const colRef = collection(db, "users/" + user + "/books/" + book.title + "/content");
    //             const querySnapshot = await getDocs(colRef);
    //             const bookContent = { "sections": {}, "timestamp": book.timestamp };
    //             const results = querySnapshot.forEach((doc) => {
    //                 console.log(doc.data())
    //                 let docTitle = doc.id;
    //                 // doc.data() is never undefined for query doc snapshots
    //                 const section = docTitle.split('_')[0];
    //                 const chap = docTitle.split('_')[1];
    //                 if (!Object.keys(bookContent["sections"]).includes(section)) {
    //                     bookContent["sections"][section] = [{ "chapter": chap, "chaptername": doc.data().chaptername }]
    //                 } else {
    //                     bookContent["sections"][section].push({ "chapter": chap, "chaptername": doc.data().chaptername })
    //                 }
    //             })
    //             console.log(bookContent);
    //             let bookContentString = JSON.stringify(bookContent);
    //             try {
    //                 localforage.setItem(book.title, bookContentString);
    //                 navigate("/books/contents", {
    //                     state: {
    //                         book: book
    //                     },
    //                 });
    //             }
    //             catch (e) {
    //                 return alert("There was a problem.")
    //             }
    //         }
    //     } else {

    //         console.log("No such document!");
    //     }

    //     return
    // }



    useEffect(() => {
        getBooks()
        getStatus()
        console.log(user)
    }, [])

    const handleOpenNewBookDialog = () => {
        setOpenNewBookDialog(true)
    }

    const handleCloseNewBookDialog = () => {
        setTitle("")
        setBookError(false)
        setBookErrorText("")
        setOpenNewBookDialog(false)
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleAuthorChange = (event) => {
        setAuthor(event.target.value);
    };

    const createNewBook = (title, author, books) => {

        if (title) {
            console.log('books', books)
            if (books.some(book => book.title === title)) {
                alert('Already taken')
                setBookError(true)
                setBookErrorText(`You already have a book titled ${title}.`)
                return
            }
            createBook(title, author)
        } else {
            setBookError(true)
            setBookErrorText("No title entered")
            return
        }
    }

    const renderBooks = (books) => {
        if (books.length === 0) {
            return <div className="NoBook">
                <p>No books yet.</p>
                <p>Click "New Book" above to get started.</p>
            </div>
        }

        return <div className="Bookshelf"><Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: '20px 20px',
                    width: 200,
                    height: 300,
                    padding: 1
                },
            }}
        >

            {books.map((element, index) => {
                return (

                    element.coverUrl
                        ?

                        <ReactImageAppear
                            key={element.title}
                            src={element.coverUrl}
                            className="Cover"
                            onClick={() => goToContents(element)}
                        />
                        : <Paper
                            sx={{ backgroundColor: element.coverColor ? element.coverColor : 'beige' }}
                            className={"Book"}
                            key={element.title}
                            elevation={3}
                            onClick={() => goToContents(element)}>
                            <h3 className="BookTitle">{element.title}</h3>
                            {element.author && <p className="BookAuthor">{element.author}</p>}
                        </Paper>

                )
            })}
        </Box></div>
    }





    return (
        <div className="BooksDiv">
            <div className="BookshelfNav">
                <Grid container spacing={3}>
                    <Grid item xs={3}>

                        <div className="Logo">
                            <img src={logo} width="100px" height="auto" /><br></br>
                            <b>RoboEdit</b>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="Title">
                            <h1>Bookshelf</h1>
                        </div>
                    </Grid>
                    <Grid item xs={3}>

                        <div className="NavLink">
                            <Link class="BookLink" style={{ float: 'right', marginRight: '20', fontWeight: '500' }} onClick={() => signOutUser()}>Sign Out</Link>
                        </div>
                        {status && <div className="NavLink">
                            <a href='https://billing.stripe.com/p/login/8wM2b3eSd9zS64w7ss' target="_blank" class="BookLink" style={{ float: 'right', marginRight: '20', fontWeight: '500' }}>Billing</a>
                        </div>
                        }
                    </Grid>
                </Grid>
            </div>
            <div>
                <Button variant="contained" color="success" startIcon={<PlusIcon />} onClick={() => handleOpenNewBookDialog()}>New Book</Button><br></br><br></br>
            </div>
            {(books && user)
                ? <div className="Bookshelf">{renderBooks(books)}</div>
                : <div className="Loader"><ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /></div>}
            {(books.length === 0) && <div className="ShelfImage">
                <img width="300px" height='auto' src={emptyShelf} />
            </div>}

            <Dialog
                fullWidth
                open={newOpenNewBookDialog}
                onClose={handleCloseNewBookDialog}
            >
                <DialogTitle>Create a new book</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the title for your new book.
                    </DialogContentText><br></br><br></br>
                    <TextField
                        error={bookError}
                        helperText={bookErrorText ? bookErrorText : `${title.length}/${maxTitleLength} characters`}
                        autoFocus
                        required
                        margin="dense"
                        id="book-title"
                        name="book-title"
                        label="Book Title"
                        inputProps={{ maxLength: maxTitleLength, style: { fontSize: 20 } }} // font size of input text
                        InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleTitleChange}
                    />
                    <TextField
                        error={bookError}
                        helperText={bookErrorText ? bookErrorText : `${title.length}/${maxTitleLength} characters.`}
                        autoFocus
                        margin="dense"
                        id="book-author"
                        name="book-author"
                        label="Author"
                        inputProps={{ maxLength: maxTitleLength, style: { fontSize: 20 } }} // font size of input text
                        InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleAuthorChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="warning" onClick={handleCloseNewBookDialog}>Cancel</Button>
                    <Button startIcon={<PlusIcon />} onClick={() => createNewBook(title, author, books)} color='success' variant='contained' type="submit">Create</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default Books