import React, { useState, useEffect, useRef } from 'react';

import { Button, Drawer, IconButton, Chip } from '@mui/material';
import CloseArrowIcon from '@mui/icons-material/Close'; // Make sure to import the correct icon
import PDFIcon from '@mui/icons-material/PictureAsPdf'; // Assuming you have an icon for PDF
import {  doc, setDoc, getDoc } from "firebase/firestore";
import { db} from "../utils/firebase";
import './PDFViewer.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PDFViewer = ({ pdfUrl, isDrawerOpen, closeDrawer, user, bookContent }) => {

    console.log("PAGE TITLE", bookContent.title)
    
    const page = parseInt(localStorage.getItem(bookContent.title + '_progress')) || bookContent.currentPdfPage || 1;

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [currentPage, setCurrentPage] = useState(page);
    const [numPages, setNumPages] = useState(0);
    const [gotoPage, setGotoPage] = useState('');
    const [savedPage, setSavedPage ] = useState(page);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const canvasRef = useRef(null);


    const fixedCanvasWidth = 500; // Set this to whatever fits your UI
    
    const handlePageChange = async (direction) => {
        setIsTransitioning(true);
        if (direction === 'next') {
          await goToNextPage();
        } else {
          await goToPreviousPage();
        }
        setTimeout(() => setIsTransitioning(false), 300); // Match this with your CSS transition time
      };
    
      useEffect(() => {
        fetchPDF(currentPage);
    }, [pdfUrl, currentPage]);



    const fetchPDF = async (pageNum) => {
        if (!pdfUrl) return;
        const pdfjsLib = await import('pdfjs-dist');
        pdfjsLib.GlobalWorkerOptions.workerSrc = 
          "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/build/pdf.worker.min.mjs";
    
        try {
            const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
            setNumPages(pdf.numPages);
            
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 1 });
            
            // Calculate scale to fit width
            const containerWidth = canvasRef.current.parentElement.clientWidth;
            const scale = containerWidth / viewport.width;
            
            const scaledViewport = page.getViewport({ scale });
    
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;
    
            const renderContext = {
                canvasContext: context,
                viewport: scaledViewport,
            };
            await page.render(renderContext).promise;
        } catch (error) {
            console.error('Error loading or rendering PDF: ', error);
        }
    };


    const goToPreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };
    
    const goToNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, numPages));
    };

    const handleJumpToPage = (e) => {

        e.preventDefault(); // Prevent the form from causing a page reload
        const pageNumber = parseInt(gotoPage, 10);
        if (pageNumber > 0 && pageNumber <= numPages) {
            setCurrentPage(pageNumber);
            setGotoPage(''); // Clear the input after jumping
        } else {
            alert('Invalid page number');
        }
    };

    const handleCloseDrawer = () => {
        localStorage.setItem(bookContent.title + '_progress', currentPage)
        setSavedPage(currentPage)
        closeDrawer()
        saveProgress(currentPage)
    }

    const saveProgress = async (page) => {


        await setDoc(doc(db, "users/" + user + "/books/" + bookContent.title), {
            currentPdfPage: page
        }, { merge: true }).then(
            console.log("Saved page progress")
        )
    }
    

    return (
        <>

            <Drawer
                hideBackdrop
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}
            >
                <div style={{ 
                    backgroundColor: 'lightgray',
                    width: '75vh', 
                    height: '100vh',
                    overflowY: 'auto', 
                    scrollbarWidth: 'thin'
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleCloseDrawer()}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        <CloseArrowIcon />
                    </IconButton>
                    <center>
                    <Chip color='warning' label={'Beta'} />&nbsp;&nbsp;&nbsp;
                    <b>Source PDF Viewer</b> - Page {currentPage} of {numPages}

                    <div>
                        
                    <form onSubmit={handleJumpToPage}>
                            <input type="text" placeholder="Go to page..." value={gotoPage} onChange={(e) => setGotoPage(e.target.value)} />
                            <Button color='info' type="submit">Go</Button>
                        </form>
                    </div>
                      
                        
                </center>
                <div style={{ paddingTop: '0px', position: 'relative', width: '100%', height: '100%' }}>
        <canvas 
          ref={canvasRef} 
          id="the-canvas" 
          style={{
            width: '100%',
            height: '100%',
            transition: 'opacity 0.3s ease-in',
            opacity: isTransitioning ? 0 : 1,
          }}
        ></canvas>
        <div 
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '45%',
          height: '100%',
          cursor: currentPage > 1 ? 'pointer' : 'default',
          backgroundColor: showLeftArrow ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
          transition: 'background-color 0.3s ease',
          zIndex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
        onMouseEnter={() => setShowLeftArrow(true)}
        onMouseLeave={() => setShowLeftArrow(false)}
        onClick={() => currentPage > 1 && handlePageChange('previous')}
      >
        {currentPage > 1 && showLeftArrow && (
          <ArrowBackIosNewIcon style={{ opacity: 0.5, marginTop: '20px' }} />
        )}
      </div>
      <div 
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          width: '45%',
          height: '100%',
          cursor: currentPage < numPages ? 'pointer' : 'default',
          zIndex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          backgroundColor: showRightArrow ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
          transition: 'background-color 0.3s ease',
        }}
        onMouseEnter={() => setShowRightArrow(true)}
        onMouseLeave={() => setShowRightArrow(false)}
        onClick={() => currentPage < numPages && handlePageChange('next')}
      >
        {currentPage < numPages && showRightArrow && (
          <ArrowForwardIosIcon style={{ opacity: 0.5, marginTop: '20px' }} />
        )}
      </div>
      </div>
                </div>
            </Drawer>
        </>
    );
};

export default PDFViewer;