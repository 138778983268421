import React from "react"
import Login from './Login/Login';
import Refiner from './Refiner/Refiner';

import './App.css';
import Editor from './Editor.js'
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Books from './Books/Books.js'
import Contents from './Contents/Contents'
import Subscribe from './Subscribe/Subscribe'
import OCRScanner from "./OCRScanner/OCRScanner.jsx";

import { getAuth } from "firebase/auth";

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  palette: {  
    secondary: {
      main: '#E4D6C4',
    },
    white: {
       main: '#fff',
      contrastText: '#000'
    },
    metal: {
      main: '#a4b7bc'
    },
    // You can add more custom colors here
    lightRed: {
      main: '#E77474',
      contrastText: '#ffffff'
    },
    lightGreen: {
      main: '#33b249',
      contrastText: '#ffffff'
    },
  },
});


function App() {


  return (
    <ThemeProvider theme={customTheme}>
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/books/ocr-refinement" element={<Refiner />} />
          <Route exact path="/editor" element={<Editor />} />
          <Route exact path="/books" element={<Books />} />
          <Route exact path="/books/contents" element={<Contents />} />
          <Route exact path="/subscribe" element={<Subscribe />} />
          <Route exact path="/ocr-scanner" element={<OCRScanner />} />

        </Routes>
      </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
