import React from 'react';
import AutoFixIcon from '@mui/icons-material/AutoFixNormal';
import DifferenceIcon from '@mui/icons-material/Difference';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton, Tooltip } from '@mui/material';


const AssistButton = (props) => {

  const [loading, setLoading] = React.useState(false);


  const useAssist = () => {
    props.clicked();
  }

  const renderButton = () => { }

  return (
    <Tooltip placement='top' title={props.tip}>
      {props.isIconBtn ? <IconButton onClick={useAssist} key={props.index} sx={{
        backgroundColor: 'var(--button-background-color)',
        color: 'var(--button-text-color)',
        '&:hover': {
          backgroundColor: 'var(--button-background-color)',
        },
      }} size="small">
        {props.children}
      </IconButton> :
        <LoadingButton
          color="success"
          key={props.index}
          size="large"
          variant="filledTonal"
          endIcon={props.icon}
          loading={props.loadingState === props.index ? true : false}
          onClick={useAssist}>
        </LoadingButton>}
    </Tooltip>
  )
}

export default AssistButton;