import { React, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import './Login.css';
import { Box, TextField, IconButton, Button, Typography, Step, InputAdornment, Stepper, StepLabel, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from "@mui/material"
import { collection, setDoc, doc } from "firebase/firestore";
import { db, auth } from "../utils/firebase";
import logo from "../assets/robotedit.png";
import docxIcon from '../assets/docx.png';
import epubIcon from '../assets/epub.png';

import proofreadIcon from '../assets/proofread.png';
import flowchart from '../assets/flowchart.png';
import GoogleButton from 'react-google-button'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import ScanIcon from '@mui/icons-material/DocumentScannerRounded'
import MailIcon from '@mui/icons-material/Mail'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import PasswordIcon from '@mui/icons-material/Lock'


import CheckMarkIcon from '@mui/icons-material/CheckCircle'
import StarsIcon from '@mui/icons-material/AutoAwesome'
import PublishIcon from '@mui/icons-material/Publish';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';



const provider = new GoogleAuthProvider();


const Login = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');



    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState(null);
    const [userEmailError, setUserEmailError] = useState(false)
    const [userEmailSuccess, setUserEmailSuccess] = useState(false)
    const [userPassword, setUserPassword] = useState(null);
    const [userPasswordError, setUserPasswordError] = useState(false)
    const [confirmUserPassword, setConfirmUserPassword] = useState(null);
    const [confirmUserPasswordError, setConfirmUserPasswordError] = useState(false)
    const [newUser, setNewUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [helperTextPassword, setHelperTextPassword] = useState("")
    const [helperConfirmTextPassword, setHelperConfirmTextPassword] = useState("")
    const [helperTextEmail, setHelperTextEmail] = useState("")
    const [resetEmailSent, setResetEmailSent] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false);

    const steps = [
        'Use an OCR to scan content',
        'AI-assisted copy editing',
        'Generate and approve final proofs',
        'Export book as .docx or .epub3',
    ];


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                //do your logged in user crap here
                setUserEmail(user.email);
                navigate("/books");
            } else {
                console.log("Logged out");
                setUserEmail(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user)
            localStorage.setItem("user", user.uid)
            localStorage.setItem("userEmail", user.email)
            // ...
            navigate("/books");
        })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    const signin = () => {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        login(email, password)
    }

    const signOutUser = () => {
        signOut(auth).then(() => {
            alert("Signed out")
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    const register = () => {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        signup(email, password)
    }

    const handleNewUser = (newUser) => {
        setNewUser(!newUser)
    }

    const signInGoogle = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user.uid;
                const userEmail = result.user.email;
                localStorage.setItem("user", user)
                localStorage.setItem("userEmail", userEmail)

            
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    const goToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: "smooth" });
    }

    const handleCancel = () => {
        setUserEmail('')
        setUserPassword('')
        setConfirmUserPassword('')
        setConfirmUserPasswordError(false)
        setUserPasswordError(false)
        setUserEmailError(false)
        setHelperTextEmail('')
        setHelperConfirmTextPassword('')
        setDialogOpen(false)

    }

    const handleOpen = () => {
        setDialogOpen(true)
        setNewUser(false)
    }

    const handleUserEmailChange = (event) => {
        setUserEmail(event.target.value); // Update email state with current input value
    };

    const handleUserPasswordChange = (event) => {
        setUserPassword(event.target.value); // Update email state with current input value
    };

    const handleConfirmUserPasswordChange = (event) => {
        setConfirmUserPassword(event.target.value); // Update email state with current input value
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle password visibility state
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword); // Toggle password visibility state
    };

    // Handle sign in with email and password
  const handleSignInMethod = () => {
    // Reset previous errors
    setUserEmailError(false);
    setUserPasswordError(false);

    // Firebase sign-in with email and password
    signInWithEmailAndPassword(auth, userEmail, userPassword)
      .then((userCredential) => {
        // Signed in successfully
        const user = userCredential.user;
        const userEmail = userCredential.userEmail;
        console.log('User signed in:', user);
        // Optionally, you can redirect or show a success message here
        localStorage.setItem('user', user.uid);
        localStorage.setItem('userEmail', user.email);
      })
      .catch((error) => {
        // Handle sign-in errors
        console.error('Error signing in:', error.code, error.message);
        // Optionally, you can display an error message to the user
        if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
          setUserEmailError(true);
          setHelperTextEmail('The user was not found or the email is invalid.')
        } else if (error.code === 'auth/wrong-password') {
            setUserPasswordError(true);
            setHelperTextPassword('This password does not match the email address.')
        } else if (error.code === 'auth/missing-password') {
            setUserPasswordError(true);
            setHelperTextPassword('No password entered.')
        }
      });
  };

    const handleSignUp = () => {
        // Reset previous errors
        setUserEmailError(false);
        setUserPasswordError(false);
        setConfirmUserPasswordError(false);
        setHelperConfirmTextPassword('');

        // Email validation (simple format check)
        if (!validateEmail(userEmail)) {
            setUserEmailError(true);
            setHelperTextEmail("This email address is invalid")
            return;
        }

        // Password checks
        if (userPassword !== confirmUserPassword) {
            setConfirmUserPasswordError(true);
            setHelperConfirmTextPassword('The passwords do not match');
            return;
        }

        if (userPassword.length < 6) {
            setUserPasswordError(true);
            setHelperConfirmTextPassword('Password must be at least 6 characters long.');
            return;
        }

        // Firebase sign-up with email and password
        createUserWithEmailAndPassword(auth, userEmail, userPassword)
            .then((userCredential) => {
                // Signed up successfully
                console.log('User signed up:', userCredential.user);
                const user = userCredential.user;
                const userEmail = userCredential.userEmail;
                localStorage.setItem("user", user)
                localStorage.setItem("userEmail", userEmail)
                // Optionally, you can redirect or show a success message here
            })
            .catch((error) => {
                // Handle sign-up errors (e.g., email already in use)
                alert('Error signing up')
                console.error('Error signing up:', error.message);
                // Optionally, you can display an error message to the user
            });
    };

    // Handle sending reset password email
  const handleForgotPassword = () => {
    if (!userEmail) {
        setUserEmailError(true);
        setHelperTextEmail("Enter your email address. If an account exists you will receive a password reset email.")
    }
    // Reset previous errors and status
    setUserEmailError(false);
    setResetEmailSent(false);

    // Validate email format
    if (!validateEmail(userEmail)) {
      setUserEmailError(true);
      setHelperTextEmail("Invalid email address format")
      return;
    }

    // Send password reset email
    sendPasswordResetEmail(auth, userEmail)
      .then(() => {
        // Password reset email sent successfully
        setUserEmailSuccess(true)
        setUserEmailError(false)
        setHelperTextEmail("✅ Password reset email sent to your mailbox.")
        setResetEmailSent(true);
      })
      .catch((error) => {
        // Handle errors (e.g., user not found)
        console.error('Error sending reset email:', error.code, error.message);
        // Optionally, you can display an error message to the user
        if (error.code === 'auth/user-not-found') {
          setUserEmailError(true);
          setHelperTextEmail("No user with that email found")
        }
      });
  };

    const validateEmail = (email) => {
        // Basic email format validation (regex check)
        // You can enhance this with more robust email validation if needed
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    return (
        <div style={{overflowX: 'hidden'}}>
        {/* <header className="App-header"> */}
            <div>
                <div id='home' className="Login">
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3" component="h3">
                        RoboEdit
                    </Typography>
                    <center>
                        <img width="150px" height="150px" src={logo} />
                    </center>
                    <Typography sx={{ width: '40' }} variant="h6" component="subtitle2" > Create, edit, and format eBooks, manuscripts and more using the power of AI.</Typography>
                    {/* <div className="LoginBox">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                inputProps={{ style: { backgroundColor: "white", color: "black" } }}
                                type="email"
                                id="email"
                                InputLabelProps={{
                                    style: { color: 'gray' },
                                }}
                                label="Email"
                                variant="filled" />
                        </div>
                        <div>
                            <TextField
                                inputProps={{ style: { backgroundColor: "white", color: "black" } }}
                                InputLabelProps={{
                                    style: { color: 'gray' },
                                }}
                                type="password"
                                id="password"
                                label="Password"
                                variant="filled" />
                        </div>
                    </Box>
                </div> */}

                    <div className="Section">
                        {/* <Typography sx={{ fontWeight: 'bold' }} variant="h4" component="h4">
                            Manuscript to Proofs
                        </Typography>
                        <Typography>
                            FormatAI can help with every step of preparing your manuscript for publishing.
                        </Typography> */}
                        <div className="Stepper">
                            <Box sx={{ width: '1000px' }}>
                                <Stepper activeStep={4} alternativeLabel>
                                    <Step className="OCRStep">
                                <StepLabel onClick={() => goToSection('hitl')} sx={{ fontSize: '30px' }} StepIconComponent={ScanIcon}><span className="StepText">OCR Scan</span></StepLabel>
                                    </Step>
                                    <Step className="AssistStep">
                                        <StepLabel onClick={() => goToSection('hitl')} StepIconComponent={StarsIcon}><span className="StepText">AI-Assisted Edit</span></StepLabel>
                                    </Step>
                                    <Step className="ProofStep">
                                        <StepLabel onClick={() => goToSection('proofs')} StepIconComponent={CheckMarkIcon}><span className="StepText">Review Proofs</span></StepLabel>
                                    </Step>
                                    <Step className="ExportStep">
                                        <StepLabel onClick={() => goToSection('export')} StepIconComponent={PublishIcon}><span className="StepText">Export Files as .docx</span></StepLabel>
                                    </Step>
                                </Stepper>
                            </Box>
                        </div>
                        <div>
                            <div className="LearnMore">
                                <div className="AuthButtons">

                               
                                    <Button
                                    color='success'
                                    size='large'
                                    variant="contained"
                                    onClick={() => handleOpen()}>
                                    Sign In / Sign Up
                                </Button>
                                </div>
                                <Button
                                    size='medium'
                                    color={'info'}
                                    variant="outlined"
                                    // sx={{border: '1px solid black', backgroundColor: 'none' }}
                                    onClick={() => goToSection('hitl')}>
                                    Learn More
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="SectionWhite">
                    <div id="hitl" className="Copy">
                        <Typography sx={{ fontWeight: 'bold', padding: 3 }} variant="h4" component="h4">
                            AI-Assisted, Human&#x2011;in&#x2011;the&#x2011;loop copyediting
                        </Typography>
                        <Typography variant="paragraph" component="paragraph">
                            <p>
                                Manuscripts digitized via OCR (Optical Character Recognition), often contain typographical errors, or <b>scannos</b>.
                            </p>
                        </Typography>
                        <Typography variant="paragraph" component="paragraph">
                            <p>
                                RoboEdit leverages AI to correct scannos and remove OCR-introduced line breaks with a high level of accuracy and speed.
                            </p>
                        </Typography>
                    </div>
                    <div className={"Diff"}>
                        <ReactDiffViewer
                            hideLineNumbers
                            oldValue={`Unon arriving'at Fort Larimie, we were received m a ver> sIvSai of our party disposed of their oxen and wagons, taking horses m Sc^n °e This tliey were induced to do, under the impression that their w4ons”could not be laken to Oregon, of which they were assured by he gentlemen of those forts and other mountaineers.`}
                            compareMethod={DiffMethod.WORDS}
                            newValue={"Upon arriving at Fort Laramie, we were received in a very hospitable manner. Several of our party disposed of their oxen and wagons, taking horses in exchange. This they were induced to do, under the impression that their wagons could not be taken to Oregon, of which they were assured by the gentlemen of those forts and other mountaineers."}
                            splitView={false}
                            styles={{
                                diffContainer: {
                                    wordBreak: 'break-word',
                                },
                                contentText: {
                                    textAlign: 'left',
                                    fontSize: '18.5px',
                                    lineHeight: '20.5px!important'
                                },
                                wordDiff: {
                                    padding: '0px',
                                    display: 'inline'
                                }
                            }}
                        />
                        <Typography variant="paragraph" component="paragraph">
                            <div className="Copy">
                                <p>
                                    See a before and after comparison to see what changed and ensure AI-suggested edits are true to the original text.
                                </p>
                                <p>
                                    By removing this often time-consuming task, the editor can focus more on the actual content and quality of the text.
                                </p>
                            </div>
                        </Typography>
                        <Button
                                    size='medium'
                                    color={'info'}
                                    variant="outlined"
                                    // sx={{border: '1px solid black', backgroundColor: 'none' }}
                                    onClick={() => goToSection('proofs')}>
                                    Learn More
                                </Button>
                    </div>
                    <br></br><br></br>
                    <div id="proofs" className="SectionGray">
                        <Typography sx={{ fontWeight: 'bold' }} variant="h4" component="h4">
                            Generate Proofs
                        </Typography>
                        <Typography variant="paragraph" component="paragraph">
                            <div className="Copy">
                                <p>
                                    Generate proofs for your manuscript to review the final product before exporting.
                                </p>
                                <div style={{ display: 'block', width: '200px', margin: 'auto' }}>
                                    <img width="200px" height="auto" src={proofreadIcon} />
                                </div>
                                <p>
                                    Perform final edits and introduce additional formatting your manuscript, or provide more prompts to RoboEdit to see aditional AI-suggested improvements.
                                </p>
                            </div>
                        </Typography>
                    </div>
                    <div id="export" className="SectionExport">
                        <p>
                            <Typography sx={{ fontWeight: 'bold', padding: 1 }} variant="h4" component="h4">
                                Export and Publish
                            </Typography>
                            <Typography variant="paragraph" component="paragraph">
                                <div className="Copy">
                                    <p>
                                        After you've finished editing and proofreading your manuscript, RoboEdit can export your manuscript as a <b>.docx</b> file.
                                    </p>
                                    <div className='FlowChart'>
                                        <img className='FlowChartImg' src={flowchart} />
                                    </div>
                                    <p>
                                        You can import .docx files into eBook creator software such as Kindle Create, or you can convert .docx to .epub with Google Docs.
                                    </p>
                                    <p>
                                        Edit smarter and publish faster with RoboEdit.
                                    </p>
                                </div>
                            </Typography>
                            <br></br><br></br>
                                <Button
                                    variant="contained"
                                    onClick={() => goToSection('home')}>
                                    Get Started
                                </Button>
                        </p>
                    </div>
                </div>
                           
            </div >
             <Dialog 
                                open={dialogOpen}
                                fullWidth
                                >
                                { newUser && <DialogTitle>RoboEdit - Sign Up</DialogTitle>}
                                { !newUser && <DialogTitle>RoboEdit - Sign In</DialogTitle>}
                                { newUser && <DialogContent>Welcome! Please create an account to get started.</DialogContent> }
                                <DialogContent>
                            
                                <TextField
                                    autoFocus
                                    error={userEmailError}
                                    helperText={helperTextEmail}
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    value={userEmail}
                                    onChange={handleUserEmailChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PasswordIcon />
                                          </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    tabIndex="-1"
                                                    aria-label="toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                      }}
                                    value={userPassword}
                                    onChange={handleUserPasswordChange}
                                    margin="dense"
                                    id="password"
                                    helperText={helperTextPassword}
                                    error={userPasswordError}
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                />
                                { newUser && <TextField
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PasswordIcon />
                                          </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    tabIndex="-1"
                                                    aria-label="toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                      }}
                                    onChange={handleConfirmUserPasswordChange}
                                    value={confirmUserPassword}
                                    error={confirmUserPasswordError}
                                    helperText={helperConfirmTextPassword}
                                    margin="dense"
                                    id="confirm-password"
                                    label="Confirm Password"
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                />
                                }
                                <br></br><br></br>
                                <Link onClick={() => handleForgotPassword()}>Forgot Password?</Link>
                                {/* <center>or</center> */}
                                <br></br>
                                 <GoogleButton
                                        className="GoogleButton"
                                        onClick={() => { signInGoogle() }}
                                    /><br></br><br></br>
                                { !newUser && <DialogContentText>
                                    <center><Link onClick={() => handleNewUser(newUser)}><b>New to RoboEdit? Sign up.</b></Link></center>
                                </DialogContentText>}
                                </DialogContent>
                                
                                <DialogActions>
                                <Button 
                                tabIndex="-1"
                                onClick={() => handleCancel()} color="primary">
                                    Cancel
                                </Button>
                                {

                                newUser ? 
                                <Button onClick={handleSignUp} color="success" variant='contained'>
                                    Continue
                                </Button>
                                : <Button onClick={handleSignInMethod} color="success" variant='contained'>
                                Continue
                            </Button>
                                }
                              
                                </DialogActions>
                            </Dialog>
        </div>

    )
}

export default Login;