import React from 'react';
import logo from "../assets/robotedit.png";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TocIcon from '@mui/icons-material/Toc';

import './NavBar.css'
import { useLocation, useNavigate, Link as RouterLink  } from 'react-router-dom';

const NavBar = (props) => {
    const version = String(process.env.REACT_APP_FORMAT_AI_VERSION)
    return (
        <Grid container spacing={3}>
            <Grid item xs>

                <div className="Logo">
                    <img src={logo} width="70px" height="auto" /><br></br>
                    <b>RoboEdit</b>
                    <div className="Version">v{version}</div>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="Title">
                    {props.bookContent.title}
                </div>
            </Grid>
            <Grid item xs>
                    {/* <div className="NavLink">
                    <Button
                    startIcon={<TocIcon />}
                    variant='contained'
                    color='info'
                    component={RouterLink}
                    to="/books/contents"
                    state={{ book: props.bookContent }}
                >
                    Table of Contents
                </Button>
                </div> */}
            </Grid>
        </Grid>
    )
}


export default NavBar;