import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbv1Y-_fHb6hOr4vG5o53-O10YrSV5pEA",
  authDomain: "formatai.firebaseapp.com",
  projectId: "formatai",
  storageBucket: "formatai.appspot.com",
  messagingSenderId: "38701382936",
  appId: "1:38701382936:web:cd5c12d71aec7282bc726d",
  measurementId: "G-L86FJNQB58"
};

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
