export function removeScannedHyphens(text) {
    // Remove hyphens at the end of lines followed by a space
    const cleanedText = text.replace(/-\s/g, '');
    return cleanedText;
}

export function getLastCharacter(inputString) {
    if (inputString.length === 0) {
        return null; // Empty string
    }
    return inputString.charAt(inputString.length - 1);
}

export function getLastTwoCharacters(inputString) {
    if (inputString.length === 0) {
        return null; // Empty string
    }
    return inputString.charAt(inputString.length - 2);
}

export function trimLastCharacter(inputString) {
    if (inputString.length > 0 && inputString.endsWith('s')) {
        return inputString.slice(0, -1);
    }
    return inputString;
}

export function isPossessiveContraction(word) {
    return word.match(/['’]s$/);
}

export function trimPossessiveContraction(word) {
    return word.replace(/['’]s$/, '');
}