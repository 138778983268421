// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions"


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAbv1Y-_fHb6hOr4vG5o53-O10YrSV5pEA",
    authDomain: "formatai.firebaseapp.com",
    projectId: "formatai",
    storageBucket: "formatai.appspot.com",
    messagingSenderId: "38701382936",
    appId: "1:38701382936:web:cd5c12d71aec7282bc726d",
    measurementId: "G-L86FJNQB58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();
const functions = getFunctions();
const storage = getStorage();

export { auth, db, storage, functions };