export const regexScannos = {
    "\\blt\\b": "It",
    "\\bl\\b(?!\\s[a-z])": "I",
    "\\b(lt|l)\\s": "I ",
    "“T\\s": '"I ',
};

export const commonScannos = {
    '“Tt ': '"It ',
    '“TT ': '"It ',
    '“Ts ': '"Is ',
    '"Tn ': '"In ',
    '“IT ': '“It ',
    '“Te ': '“He ',
    ' Te ': ' He ',
    '“T ': '“I ',
    '“J ': '“I ',
    '“Tf': '“If',
    '“Tf ': '“If ',
    '’’': '"',
    '[ am': 'I am',
    '«': '“',
    '»': '”'
}

export const russianNames = {
    "Sacha": "Sasha",
    "Katia": "Katya"
}