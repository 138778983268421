import React, { useCallback, useEffect, Fragment, useState } from 'react';
import { useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ref, uploadString, getDownloadURL, getStorage, uploadBytes, deleteObject } from "firebase/storage";

import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, storage, auth } from "../utils/firebase";
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Accordion, Fab, Tooltip, AccordionSummary, AccordionDetails, Button, ListSubheader, InputLabel, Box, Popper, FormControl, MenuItem, Select, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, DialogActions, Grid, Chip } from '@mui/material';
import BookshelfIcon from '@mui/icons-material/LibraryBooks';
import DeleteIcon from '@mui/icons-material/Delete';
import ExportIcon from '@mui/icons-material/Download';
import PersonIcon from '@mui/icons-material/AccountCircle';

import PlusIcon from '@mui/icons-material/Add';
import PdfIcon from '@mui/icons-material/MenuBook';
import ScanIcon from '@mui/icons-material/DocumentScanner';
import TextIcon from '@mui/icons-material/TextSnippet';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, EndnoteReference, FootnoteReference, FootnoteReferenceRun } from "docx";
import localforage from 'localforage';
import logo from '../assets/robotedit.png';
import Refiner from '../Refiner/Refiner'
import { render } from "react-dom";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import FileUpload from '../FileUploader/FileUpload';
import CoverUpload from '../CoverUploader/CoverUpload';
import ReactImageAppear from "react-image-appear"

import './Contents.css';

const Contents = (props) => {
    const location = useLocation();
    const [formOpen, setFormOpen] = React.useState(false);
    const [characterFormOpen, setCharacterFormOpen] = React.useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = React.useState(false);
    const [deleteDisabled, setDeleteDisabled] = React.useState(true);
    const [elementType, setElementType] = React.useState("chapter");
    const [section, setSection] = React.useState("");
    const [sections, setSections] = React.useState([]);
    const [character, setCharacter] = React.useState({ firstname: "", nickname: "", middlename: "", lastname: "" })
    const [firstname, setFirstName] = React.useState("")
    const [middlename, setMiddleName] = React.useState("")
    const [lastname, setLastName] = React.useState("")
    const [nickname, setNickname] = React.useState("")
    const [uploadedFileUrl, setUploadedFileUrl] = useState(false);
    const [coverUrl, setCoverUrl] = useState(null);
    const [bookObj, setBookObj] = React.useState(null);
    const [updatedChapter, setUpdatedChapter] = React.useState(false);
    const { book } = location.state;
    const [bookLayout, setBookLayout] = React.useState(book.bookLayout);
    const [characters, setCharacters] = React.useState(book.characters);
    const [isAceOpen, setIsAceOpen] = React.useState(false);
    const [ocrText, setOcrText] = React.useState("");
    const [isOcrConfirmationOpen, setIsOcrConfirmationOpen] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [footnotes, setFootnotes] = React.useState(0);

    console.log("LOCATIONSTATE", location.state)

    const navigate = useNavigate();


    //create your forceUpdate hook
    const useForceUpdate = () => {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
        // A function that increment 👆🏻 the previous state like here 
        // is better than directly setting `setValue(value + 1)`
    }

    const user = localStorage.getItem("user");

    const getChapters = async () => {
        var bookData = await localforage.getItem(book.title); //wait for the localforage item
        let bookObj = JSON.parse(bookData);
        console.log("Bookobj", bookObj)
        console.log("sections", sections);
        // if(sections.lenghh) {
        //     let sections = Object.keys(bookObj["sections"]);
        //     setSections(sections);
        // }
        setBookObj(bookObj);
    }

    const handleFormClose = () => {
        setFormOpen(false);
    }

    const handleAceClose = () => {
        setIsAceOpen(false)
    }

    const handleAceOpen = () => {
        if (book.hasOcrScanned) {
            setIsAceOpen(true)
        } else {
            setIsOcrConfirmationOpen(true)
        }
    }

    const createNewChapter = () => {
        setIsAceOpen(false)
    }

    const navigateToOcrScanner = () => {
        navigate("/ocr-scanner", {
            state: {
                book: book
            },
        });
    }

    const handleOcrConfirmed = () => {
        if (uploadedFileUrl) {
            navigate("/ocr-scanner", {
                state: {
                    book: book
                },
            });
        } else {
            setIsOcrConfirmationOpen(false)
            navigateToOcrScanner()
        }
    }

    const handleCharacterFormClose = () => {
        setCharacterFormOpen(false);
        setFirstName("")
        setNickname("")
        setMiddleName("")
        setLastName("")
    }

    const handleDeleteFormClose = () => {
        setDeleteDisabled(true)
        setDeleteFormOpen(false);
    }

    const createElement = () => {
        setFormOpen(true);
    }

    const handleNavigate = () => {
        navigate('/books/ocr-refinement', { state: { book } });
    };

    const createCharacter = () => {
        setCharacterFormOpen(true);
    }

    const deleteCharacter = async (characterId) => {
        let newCharacterArr = [...characters];
        const timestamp = parseInt(Date.now() / 1000);
        const indexToDelete = newCharacterArr.findIndex(item => item.id === characterId);
        if (indexToDelete !== -1) {
            newCharacterArr.splice(indexToDelete, 1);
            await updateDoc(doc(db, "users/" + user + "/books/" + book.title), {
                characters: newCharacterArr,
                timestamp: timestamp
            })
        } else {
            console.log("Element not found with id:", characterId);
        }


        setCharacters(newCharacterArr)
    }

    const generateRandID = () => {
        // I generate the UID from two parts here 
        // to ensure the random number provide enough bits.
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    }


    const createNewCharacter = async (characters, firstname, nickname, middlename, lastname) => {
        let newCharacterArr = [...characters];
        console.log(newCharacterArr)
        let characterId = generateRandID();
        let characterObj = { "firstname": firstname, "middlename": middlename, "nickname": nickname, "lastname": lastname, "id": characterId }
        console.log("1234ASDF", characterObj)
        newCharacterArr.push(characterObj)
        setCharacters(newCharacterArr);
        location.state.characters = newCharacterArr;

        const timestamp = parseInt(Date.now() / 1000);

        await updateDoc(doc(db, "users/" + user + "/books/" + book.title), {
            characters: newCharacterArr,
            timestamp: timestamp
        }).then(() => {
            handleCharacterFormClose()
        })
    }

    const getCover = async () => {

        // const docRef = doc(db, "users/" + user + "/books/" + book.title);
        // const docSnap = await getDoc(docRef);
        // if (docSnap.exists()) {
        //     const coverUrl = docSnap.data().coverUrl;
        //     if (coverUrl) {
        //         console.log("PDF URL", coverUrl)
        //         setCoverUrl(book.title)
        //     }
        if (book.coverUrl) {
            setCoverUrl(book.coverUrl)
        } else {
            console.log("No coverUrl set yet.")
        }
    }

    const handleCoverClicked = async () => {
        const willReplaceCover = window.confirm("Replace cover image?")
        if (willReplaceCover) {
            try {
                // Get the storage reference from the download URL
                const storage = getStorage();
                let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.jpg'
                const fileRef = ref(storage, user + '/' + fileName);

                // Delete the file from storage
                await deleteObject(fileRef);

                await setDoc(doc(db, "users/" + user + "/books/" + book.title), {
                    coverUrl: ""
                }, { merge: true }).then(
                    setCoverUrl(null)
                )

                console.log('File deleted successfully');
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        }
    }

    const getSourcePdf = async () => {

        const docRef = doc(db, "users/" + user + "/books/" + book.title);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const pdfUrl = docSnap.data().sourcePdfUrl;
            if (pdfUrl) {
                console.log("PDF URL", pdfUrl)
                book.pdfUrl = pdfUrl;
                location.state.pdfUrl = pdfUrl;
                setUploadedFileUrl(pdfUrl)
            }
        } else {
            console.log("No source PDF set yet.")
        }
    }


    const createNewElement = async () => {

        const section = window.prompt("Enter Section")
        const chapter = window.prompt("Enter Chapter")
        let chaptername = window.prompt("Enter Chapter Name (optional")

        if (!chaptername) {
            chaptername = "";
        }

        if (!section || !chapter) {
            return alert("You must enter a number")
        }

        const fullChapter = section + '_' + chapter;

        const timestamp = parseInt(Date.now() / 1000);


        await setDoc(doc(db, "users/" + user + "/books/" + book.title + "/content", fullChapter), {
            chaptername: chaptername,
            paragraphs: []
        }).then(
            await updateDoc(doc(db, "users/" + user + "/books/" + book.title), {
                timestamp: timestamp
            }).then(() => {

                console.log("INITIAL", bookObj)

                if (!sections.includes(section)) {
                    console.log("Replacing section")
                    console.log(sections, section)
                    sections.push(section)
                    setSections(sections)

                    if (!bookObj) {
                        let initBookObj = {
                            "sections": {
                                [section]: [{ "chapter": chapter, "chaptername": chaptername }]
                            },
                            "timestamp": timestamp
                        }
                        return setBookObj(initBookObj)
                    }
                    console.log(bookObj)


                    bookObj["sections"][section] = [{ "chapter": chapter, "chaptername": chaptername }]
                    localforage.setItem(book.title, JSON.stringify(bookObj));
                    console.log(bookObj)
                    setBookObj(bookObj);
                    setUpdatedChapter(true);
                } else {
                    console.log("Adding chapter")
                    let arr = bookObj["sections"][section];
                    arr.push({ "chapter": chapter, "chaptername": chaptername });
                    bookObj["sections"][section] = arr
                    setBookObj(bookObj);
                }

            })
        )
    }

    const renderSections = (chapters, index) => {
        let sectionChaptersArr = {};
        return sectionChaptersArr;
    }




    const functions = getFunctions();

    const testFunction = () => {
        const scanDocument = httpsCallable(functions, 'scanDocument');
        scanDocument()
            .then((result) => {
                alert("Function ran")
            });
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value.trim())
    }

    const handleMiddleNameChange = (e) => {
        setMiddleName(e.target.value.trim())
    }

    const handleNicknameChange = (e) => {
        setNickname(e.target.value.trim())
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value.trim())
    }

    const renderName = (firstname, nickname, middlename, lastname) => {
        if (nickname) {
            nickname = ' "' + nickname + '" ';
            return firstname + " " + nickname + middlename + " " + lastname
        }
        return firstname + " " + middlename + " " + lastname
    }

    const handleChange = (event) => {
        setElementType(event.target.value);
    };

    const handleSectionChange = (event) => {
        setSection(event.target.value);
    };

    const createDocument = async (id, name, newLayout, goToChapter = false) => {
        const timestamp = parseInt(Date.now() / 1000);
        await setDoc(doc(db, "users/" + user + "/books/" + book.title + "/content", id), {
            chaptername: name,
            paragraphs: []
        }).then(
            await updateDoc(doc(db, "users/" + user + "/books/" + book.title), {
                timestamp: timestamp,
                bookLayout: newLayout
            }).then(() => {
            }))
    }

    const handleElementCreate = async () => {
        console.log("SECTION", section)

        let elementName = document.getElementById("element-name").value;
        let elementId = "id" + Math.random().toString(16).slice(2);
        let updatedBookLayoutArr = bookLayout;

        const frontMatterChoices = ["preface", "prologue", "foreword", "introduction"]
        const backMatterChoices = ["epilogue", "afterword", "appendix"]


        if (elementType === "section") {
            let sectionsArr = updatedBookLayoutArr["body"]["sections"];
            console.log(sectionsArr)
            var valueArr = sectionsArr.map(function (item) { return item.name });
            console.log(valueArr);
            if (valueArr.includes(elementName)) {
                alert("Section names must be unique.")
                return setFormOpen(false)
            }
            let maxOrder = Math.max(...sectionsArr.map(o => o.order));
            console.log(maxOrder);
            updatedBookLayoutArr["body"]["sections"].push({ "id": elementId, "name": elementName, order: maxOrder + 1 })
            // await setDoc(doc(db, "users/" + user + "/books/" + book.title + "/content", id), {
            //     chaptername: name,
            //     paragraphs: []
            // }).then(() => {
            // alert("There was an error saving the section")
            createDocument(elementId, elementName, updatedBookLayoutArr)
            return setFormOpen(false)
            // })
        }

        /* Create first element */
        if (elementType === "chapter" && (section === "none" || !section)) {
            let chaptersArr = updatedBookLayoutArr["body"]["sections"][0]["chapters"];
            if (updatedBookLayoutArr["body"]["sections"][0]["chapters"].length === 0) {
                updatedBookLayoutArr["body"]["sections"][0]["chapters"].push({ "id": elementId, "name": elementName, "order": 0 })
                createDocument(elementId, elementName, updatedBookLayoutArr)
                /* Add to existing section */
            } else {
                let maxOrder = Math.max(...chaptersArr.map(o => o.order));
                updatedBookLayoutArr["body"]["sections"][0]["chapters"].push({ "id": elementId, "name": elementName, "order": maxOrder })
                createDocument(elementId, elementName, updatedBookLayoutArr)
            }
        }
        if (elementType === "chapter" && section && section !== "none") {
            const timestamp = parseInt(Date.now() / 1000);
            let sectionObj = updatedBookLayoutArr["body"]["sections"].filter(obj => {
                return obj.name === section
            })
            let objIndex = updatedBookLayoutArr["body"]["sections"].findIndex(obj => {
                return obj.name === section
            });
            if (updatedBookLayoutArr["body"]["sections"][objIndex]["chapters"]) {
                let maxOrder = Math.max(...updatedBookLayoutArr["body"]["sections"][objIndex]["chapters"].map(o => o.order));
                updatedBookLayoutArr["body"]["sections"][objIndex]["chapters"].push({ "id": elementId, "name": elementName, "order": maxOrder });
            } else {
                updatedBookLayoutArr["body"]["sections"][objIndex]["chapters"] = [{ "id": elementId, "name": elementName, "order": 0 }];
            }
            await updateDoc(doc(db, "users/" + user + "/books/" + book.title), {
                timestamp: timestamp,
                bookLayout: updatedBookLayoutArr
            });
        }

        /* Front matter set */
        if (frontMatterChoices.includes(elementType)) {
            console.log(updatedBookLayoutArr)
            let frontMatterArr = updatedBookLayoutArr["frontMatter"];
            console.log(frontMatterArr)
            let maxOrder = frontMatterArr.length === 0
                ? 0
                : Math.max(...frontMatterArr.map(o => o.order))
            console.log(maxOrder);
            updatedBookLayoutArr["frontMatter"].push({ "id": elementId, "name": elementName, order: maxOrder })
            createDocument(elementId, elementName, updatedBookLayoutArr)
            return setFormOpen(false)
        }

        /* Back matter set */
        if (backMatterChoices.includes(elementType)) {
            console.log(updatedBookLayoutArr)
            let backMatterArr = updatedBookLayoutArr["backMatter"];
            console.log(backMatterArr)
            let maxOrder = backMatterArr.length === 0
                ? 0
                : Math.max(...backMatterArr.map(o => o.order))
            console.log(maxOrder);
            updatedBookLayoutArr["backMatter"].push({ "id": elementId, "name": elementName, order: maxOrder })
            createDocument(elementId, elementName, updatedBookLayoutArr)
            return setFormOpen(false)
        }

        setFormOpen(false)
    }

    const renderFreeChapters = () => {
        console.log(bookLayout)
        let chapters = bookLayout["body"]["sections"]["0"]["chapters"];
        console.log("CHAPTERS", chapters)
        if (!chapters) {
            return <p>No chapters yet</p>
        }
        if (chapters) {
            return chapters.map((element, index) => {
                return <Link
                    className="link"
                    to={'../editor'}
                    state={{
                        book: book,
                        characters: characters,
                        title: book.title,
                        content: element.id,
                        chapter: element.chapter,
                        chaptername: element.name,
                        section: "None",
                        pdfUrl: uploadedFileUrl
                    }}>
                    <p>{element.name}</p>
                </Link>
            })
        }
    }

    const renderFrontMatter = () => {
        console.log("FRONT MATTER")
        console.log(bookLayout)
        let frontMatter = bookLayout["frontMatter"];
        console.log("CHAPTERS", frontMatter)
        if (!frontMatter) {
            return null
        }
        if (frontMatter) {
            return frontMatter.map((element, index) => {
                return <Link to={'../editor'} state={{
                    book: book,
                    title: book.title,
                    content: element.id,
                    chapter: element.chapter,
                    chaptername: element.name,
                    section: "frontMatter"
                }}>
                    <p>{element.name}</p>
                </Link>
            })
        }
    }

    const renderNewButton = () => {
        let chapters = bookLayout["body"]["sections"]["0"]["chapters"];
        if (chapters.length > 10) {
            return <Button color="success" style={{ margin: '25px' }} variant="contained" onClick={() => createElement()} endIcon={<PlusIcon />}>New Element</Button>
        }
    }

    const renderBackMatter = () => {
        console.log("BACK MATTER")
        console.log(bookLayout)
        let backMatter = bookLayout["backMatter"];
        if (!backMatter) {
            return null
        }
        if (backMatter) {
            return backMatter.map((element, index) => {
                return <Link to={'../editor'} state={{
                    book: book,
                    title: book.title,
                    content: element.id,
                    chapter: element.chapter,
                    chaptername: element.name,
                    section: "backMatter"
                }}>
                    <p>{element.name}</p>
                </Link>
            })
        }
    }

    const renderBookSections = () => {
        let sections = bookLayout["body"]["sections"];
        console.log("SECTIONS", sections)
        if (!sections) {
            return <p>NO Sections yet</p>
        }
        if (sections) {
            return sections.map((element, index) => {
                console.log(element)
                if (element.name === "nemo") {
                    return null
                }
                return <Fragment>
                    <p><b>{element.name}</b></p>
                    {element.chapters && element.chapters.map(chapter => {
                        return <Link to={'../editor'} state={{
                            book: book,
                            characters: characters,
                            title: book.title,
                            content: chapter.id,
                            chapter: chapter.chapter,
                            chaptername: chapter.name,
                            section: element.name
                        }}>
                            <p>{chapter.name}</p>
                        </Link>
                    })}
                </Fragment>
            })
        }
    }

    const openDeleteForm = () => {
        setDeleteFormOpen(true);
    }

    const checkDeleteTitle = () => {
        if (document.getElementById('delete').value === book.title) {
            setDeleteDisabled(false)
        }
    }

    const handleCoverUpload = useCallback(async (file) => {
        try {
            const storage = getStorage();

            let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.jpg'

            const storageRef = ref(storage, user + '/' + fileName);

            // Upload the file to Cloud Storage
            const snapshot = await uploadBytes(storageRef, file);

            console.log('Uploaded a blob or file and called the Cloud Function!');

            const downloadURL = await getDownloadURL(storageRef);
            await setDoc(doc(db, "users/" + user + "/books/" + book.title), {
                coverUrl: downloadURL
            }, { merge: true }).then(() => {
                book.coverUrl = downloadURL;
                setCoverUrl(downloadURL)
            })
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }, [storage]);

    const handleFileUpload = useCallback(async (file) => {
        try {
            const storage = getStorage();

            let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.pdf'

            const storageRef = ref(storage, user + '/' + fileName);

            // Upload the file to Cloud Storage
            const snapshot = await uploadBytes(storageRef, file);

            console.log('Uploaded a blob or file and called the Cloud Function!');

            const downloadURL = await getDownloadURL(storageRef);
            await setDoc(doc(db, "users/" + user + "/books/" + book.title), {
                sourcePdfUrl: downloadURL
            }, { merge: true }).then(() => {
                setUploadedFileUrl(downloadURL)
                book.pdfUrl = downloadURL
            }
            )
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }, [storage]);

    const handleBookDelete = async () => {
        await deleteDoc(doc(db, "users/" + user + "/books/" + book.title))
            .then(async () => {
                try {
                    await deleteDoc(doc(db, "users/" + user + "/books/" + book.title + "/content")).then(navigate("/books"))

                }
                catch {
                    navigate("/books")
                }
            })
    }

    const replaceSourcePDF = async () => {
        if (window.confirm("Are you sure you want to delete your source PDF?")) {

            try {
                // Get the storage reference from the download URL
                const storage = getStorage();
                let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.pdf'
                const fileRef = ref(storage, user + '/' + fileName);

                // Delete the file from storage
                await deleteObject(fileRef);

                await setDoc(doc(db, "users/" + user + "/books/" + book.title), {
                    sourcePdfUrl: ""
                }, { merge: true }).then(() => {
                    book.pdfUrl = "";
                    if (location.state) {
                        location.state.pdfUrl = "";
                    }
                    setUploadedFileUrl(null);
                });

                console.log('File deleted successfully');
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        }
    }

    const handleOcrClose = () => {
        setIsOcrConfirmationOpen(false)
        book.hasOcrScanned = true;
        handleAceOpen()
    }

    const generateChapter = async (chaptername, chapterId, type) => {

        const docRef = doc(db, "users/" + user + "/books/" + book.title + "/content/" + chapterId);
        console.log("users/" + user + "/books/" + book.title + "/content/" + chapterId)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {

            console.log("Document data:", docSnap.data());
            let targetParagraphs = docSnap.data().paragraphs.map(a => a.text);
            let paragraphStyles = docSnap.data().paragraphs.map(a => a.style);

            console.log(targetParagraphs, paragraphStyles)

            let headingType = HeadingLevel.HEADING_2;

            if (type === "frontMatter" || type === "backMatter" || type === "freeMatter") {
                headingType = HeadingLevel.HEADING_1;
            }


            const chapterTitle = new Paragraph({
                heading: headingType,
                alignment: AlignmentType.CENTER,
                children: [
                    new TextRun(chaptername),
                    new TextRun({
                        break: 1,
                    })
                ],
            })

            const chapterContent = targetParagraphs.map((element, index) => {

                let stylesObj = paragraphStyles[index];
                let isBold = stylesObj["bold"];
                let isItalics = stylesObj["italics"];
                let isQuote = stylesObj["quote"];
                let isCentered = stylesObj["center"];
                let isSubHeader = stylesObj["subHeader"];

                let paragraphStyle = isQuote ? 'quoteStyle' : 'paraStyle';

                if (isSubHeader) {
                    return new Paragraph({
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun(
                                {
                                    text: element,
                                    italics: isItalics,
                                    bold: isBold
                                })
                        ],
                    })
                }

                let textRuns = [
                    new TextRun(
                        {
                            text: element,
                            italics: isItalics,
                            bold: isBold
                        })
                ]

                if (element.includes('<br>')) {
                    textRuns = element.split("<br>").map(line => {
                        console.log(line)
                        return new TextRun({ break: 1, text: line })
                    });
                    console.log(element)
                    console.log(textRuns)
                    // const paragraph = new Paragraph({ children: textRuns })    
                }

                if (element.includes('<i>') || element.includes('<b>') || element.includes('<sup>')) {
                    let result = element.match(/<\s*(\w+\b)(?:(?!<\s*\/\s*\1\b)[\s\S])*<\s*\/\s*\1\s*>|\S+/g);
                    console.log(result);
                    let mapped = result.flatMap((word, index) => {
                        if (word.includes('<i>')) {
                            let replacedWord = word.replace(/<[^>]+>/g, '') + " ";
                            if (result[index + 1] === "." || result[index + 1] === "," || result[index + 1] === ";") {
                                replacedWord = replacedWord.trim();
                            }
                            return new TextRun({ italics: true, text: replacedWord })
                        }
                        if (word.includes('<b>')) {
                            let replacedWord = word.replace(/<[^>]+>/g, '') + " ";
                            if (result[index + 1] === "." || result[index + 1] === "," || result[index + 1] === ";") {
                                replacedWord = replacedWord.trim();
                            }
                            return new TextRun({ bold: true, text: replacedWord })
                        }

                        // Handle superscript text
                        // Handle superscript text
                        if (word.includes('<sup>')) {
                            let parts = word.split(/<sup>|<\/sup>/);
                            setFootnotes(footnotes + 1)
                            return [
                                new TextRun({ text: parts[0] }),
                                new FootnoteReferenceRun(parseInt(parts[1])),
                                new TextRun({ text: ' ' }) // Add space after superscript
                            ];
                        }

                        word = word + " ";
                        if (result[index + 1] === "." || result[index + 1] === ",") {
                            word = word.trim();
                        }
                        return new TextRun({ text: word })
                    });
                    console.log(mapped);
                    textRuns = mapped
                }


                return new Paragraph({
                    style: paragraphStyle,
                    alignment: isCentered ? AlignmentType.CENTER : AlignmentType.LEFT,
                    children: textRuns
                })
            })

            console.log(chapterContent)
            let chapterArr = [chapterTitle, ...chapterContent];
            return chapterArr;

        } else {
            // doc.data() will be undefined in this case
            return alert("There was a problem finding this document");
        }
    }

    // Function to manually open the Popper
    const openPopper = (anchor) => {
        setAnchorEl(anchor);
    };

    // Function to manually close the Popper
    const closePopper = () => {
        setAnchorEl(null);
    };

    const pdfPopper = Boolean(anchorEl);
    const id = pdfPopper ? 'simple-popper' : undefined;


    /* Export book as DOCX */
    const exportDocx = async (bookLayout) => {

        console.log(bookLayout)

        // let chaptername = bookObj.chaptername;

        const bookDocs = collection(db, "users/" + user + "/books/" + book.title, "content");

        let chapters = [];


        /* First, we cycle through the main book sections to determine if any content exists */

        let frontMatterArr = bookLayout['frontMatter'];
        let freeBodyMatterArr = bookLayout['body']['sections']['0']['chapters'];
        let sectionsArr = bookLayout['body']['sections'];
        let backMatterArr = bookLayout['backMatter'];
        console.log('FREE', freeBodyMatterArr)
        /* FRONT MATTER */
        if (frontMatterArr.length > 0) {
            console.log('Generating FRONT MATTER: ', bookLayout["frontMatter"])
            for (let i = 0; i < frontMatterArr.length; i++) {
                let content = await generateChapter(frontMatterArr[i].name, frontMatterArr[i].id, "frontMatter")
                chapters = [...chapters, ...content];
            }
        }
        /* FREE BODY MATTER */
        if (freeBodyMatterArr.length > 0) {
            console.log("Generating FREE MATTER", freeBodyMatterArr)
            for (let i = 0; i < freeBodyMatterArr.length; i++) {
                let content = await generateChapter(freeBodyMatterArr[i].name, freeBodyMatterArr[i].id, "freeMatter")
                chapters = [...chapters, ...content];
            }
        }

        /* SECTION BODY MATTER */
        if (sectionsArr.length > 0) {
            /* Remove the free matter object if present */
            sectionsArr = sectionsArr.filter(obj => obj.id !== "0");
            sectionsArr.sort((a, b) => a.order - b.order);
            console.log('Generating SECTION MATTER: ', sectionsArr)

            let sectionsContent = [];
            for (let i = 0; i < sectionsArr.length; i++) {
                let sectionContent = [];
                console.log(sectionsArr[i])
                const sectionTitle = new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun(sectionsArr[i].name),
                        new TextRun({
                            break: 1,
                        })
                    ],
                })

                let chaptersArr = sectionsArr[i].chapters;
                for (let i = 0; i < chaptersArr.length; i++) {
                    console.log(chaptersArr[i].name, chaptersArr[i].id)
                    let content = await generateChapter(chaptersArr[i].name, chaptersArr[i].id, "sectionMatter")
                    sectionContent = [...sectionContent, sectionTitle, ...content];
                }
                sectionsContent = [...sectionsContent, ...sectionContent];
            }
            chapters = [...chapters, ...sectionsContent];
        }

        /* BACK MATTER */
        if (bookLayout['backMatter'].length > 0) {
            console.log('Generating BACK MATTER: ', bookLayout["backMatter"])
            for (let i = 0; i < backMatterArr.length; i++) {
                let content = await generateChapter(backMatterArr[i].name, backMatterArr[i].id, "backMatter")
                chapters = [...chapters, ...content];
            }
        }

        console.log(chapters);

        const footnoteObj = {};

        for (let i = 1; i <= footnotes; i++) {
            footnoteObj[i] = {
                children: [new Paragraph(` Content for footnote ${i}`)],
            };
        }

        console.log('footnoteobj', footnoteObj)
        console.log(footnotes)

        const docx = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "quoteStyle",
                        name: "Quotation",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20
                        },
                        paragraph: {
                            size: 16,
                            spacing: {
                                before: 120,
                                after: 240
                            },
                            indent: {
                                left: 720
                            },
                        }
                    },
                    {
                        id: "paraStyle",
                        name: "Paragraph",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 24
                        },
                        paragraph: {
                            size: 24,
                            spacing: {
                                before: 120,
                                after: 120
                            },
                            indent: {
                                firstLine: 370
                            },
                        }
                    },
                    {
                        id: "subHeaderStyle",
                        name: "SubHeader",
                        basedOn: "heading3",
                        next: "Normal",
                        quickFormat: true,
                        paragraph: {
                            size: 24,
                            bold: true,
                            alignment: AlignmentType.CENTER,
                        }
                    }],
            },
            footnotes: footnoteObj,
            sections: [
                {
                    properties: {},
                    children: chapters
                },
            ],
        });

        // Used to export the file into a .docx file
        Packer.toBlob(docx).then(blob => {
            console.log(blob);
            saveAs(blob, `${book.title}.docx`);
            console.log("Document created successfully");
        });



    }




    useEffect(() => {
        getChapters()
        getSourcePdf()
        getCover()
    }, [])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs>

                    <div className="StickyLogo">
                        <img src={logo} width="100px" height="auto" /><br></br>
                        <b>RoboEdit</b>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="Title">
                        <h1>{book.title}</h1>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="NavLink">
                        <Fab
                            className="FabBtnBoxTocExtended"
                            variant="extended"
                            color='secondary'
                            to="/books"
                            component={RouterLink}
                            state={{ book: book }}
                        >
                            <BookshelfIcon sx={{ mr: 1 }} />
                            Bookshelf
                        </Fab>

                    </div>
                </Grid>
            </Grid>
            {coverUrl
                // ? <img 
                // style={{cursor: 'pointer'}} 
                // src={coverUrl} 
                // onClick={handleCoverClicked} 
                // width="200px" 
                // height="auto" />
                ? <ReactImageAppear
                    src={coverUrl}
                    className="CoverImg"
                    onClick={handleCoverClicked}
                />
                : <CoverUpload bookTitle={book.title} onFileUpload={handleCoverUpload} />
            }

            <center>
                <br></br>
                <div style={{ width: '300px' }}>
                    <Accordion onClick={() => closePopper()} id="modal-close-anchor">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                            <PdfIcon /> &nbsp; &nbsp; &nbsp; Source PDF
                        </AccordionSummary>
                        <AccordionDetails>
                            {uploadedFileUrl ?
                                <Fragment>
                                    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                                        <object type="application/pdf" data={uploadedFileUrl} width="100%" height="200px"></object>
                                    </div>
                                    <Button onClick={replaceSourcePDF} variant='contained' color='warning'>Replace Source PDF</Button>
                                </Fragment>
                                : <FileUpload text="Drag & drop a PDF file here, or click to select one." onFileUpload={handleFileUpload} />
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Popper placement={'bottom'} id={'popper'} open={pdfPopper} anchorEl={anchorEl}>
                        <Box sx={{ height: '50px', border: 2, borderRadius: '5px', borderColor: 'black', p: 1, bgcolor: '#FFCCCB' }}>
                            <b>In order to use RoboEdit OCR, please upload a PDF. <a href="#" onClick={(event) => { event.preventDefault(); closePopper(); }}>Dismiss</a></b>
                        </Box>
                    </Popper>
                </div>
            </center>
            {/* <button onClick={() => testFunction()}>Test Function</button> */}
            {/* <p>Welcome to your new book! Let's create a new element to get started.</p> */}
            <Button color="secondary" style={{ margin: '25px' }} variant="contained" onClick={() => navigateToOcrScanner()} endIcon={<ScanIcon />}>OCR Scanner</Button>
            <Button startIcon={<TextIcon />} color="secondary" style={{ margin: '25px' }} variant="contained" onClick={() => handleAceOpen()}>Source Text</Button>

            <Refiner clickedNewChapter={() => createNewChapter()} bookTitle={book.title} aceOpen={isAceOpen} user={user} book={book} clicked={handleAceClose} />

            <Dialog open={formOpen} onClose={handleFormClose}>
                <DialogTitle>Create New Element</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What kind of book element do you want to create?
                    </DialogContentText>
                    <br></br>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="element-type-selector-label">Element Type</InputLabel>
                            <Select
                                labelId="element-type-selector"
                                id="element-type-selector"
                                value={elementType}
                                label="Element type"
                                onChange={handleChange}
                            >
                                <ListSubheader>Body</ListSubheader>
                                <MenuItem value={"chapter"}>Chapter</MenuItem>
                                <MenuItem value={"section"}>Section</MenuItem>
                                <ListSubheader>Front Matter</ListSubheader>
                                <MenuItem value={"foreward"}>Foreward</MenuItem>
                                <MenuItem value={"introduction"}>Introduction</MenuItem>
                                <MenuItem value={"preface"}>Preface</MenuItem>
                                <MenuItem value={"prologue"}>Prologue</MenuItem>
                                <ListSubheader>Back Matter</ListSubheader>
                                <MenuItem value={"epilogue"}>Epilogue</MenuItem>
                                <MenuItem value={"afterword"}>Afterword</MenuItem>
                                <MenuItem value={"appendix"}>Appendix</MenuItem>
                            </Select><br></br><br></br>
                        </FormControl>
                        {(elementType === "chapter") && (bookLayout["body"]["sections"].length > 1) &&
                            <FormControl fullWidth>
                                <InputLabel id="section-selector-label">Section</InputLabel>
                                <Select
                                    labelId="section-selector"
                                    id="section-selector"
                                    label="Section"
                                    value={section}
                                    onChange={handleSectionChange}
                                    helperText="Assign this chapter to a section"
                                >
                                    {bookLayout["body"]["sections"].map(element => {
                                        console.log(element.name)
                                        if (element.name === "nemo") {
                                            return <MenuItem value={"nemo"}>None</MenuItem>
                                        }
                                        return <MenuItem value={element.name}>{element.name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        }
                        <TextField
                            autoFocus
                            margin="dense"
                            id="element-name"
                            label="Element Name"
                            type="text"
                            fullWidth
                            helperText="Ex: Chapter 1"
                            variant="standard"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="info" onClick={handleFormClose}>Cancel</Button>
                    <Button color="success" onClick={handleElementCreate}>Create</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteFormOpen} onClose={handleFormClose}>
                <DialogTitle>Delete book?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <b>⚠️ Warning:</b> This will permanently delete the book.<br></br><br></br>
                    </DialogContentText>
                    <DialogContentText>
                        To delete this book, type in the title of the book below.
                    </DialogContentText><br></br>
                    <DialogContentText color="black">
                        {book.title}
                    </DialogContentText>
                    <br></br>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <TextField
                                onChange={checkDeleteTitle}
                                autoFocus
                                margin="dense"
                                id="delete"
                                type="text"
                                fullWidth
                                helperText="Type in the full name of book"
                                variant="standard"
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="info" onClick={handleDeleteFormClose}>Cancel</Button>
                    <Button disabled={deleteDisabled} color="warning" variant="contained" onClick={() => handleBookDelete()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isOcrConfirmationOpen}
                onClose={handleOcrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Use RoboEdit's OCR?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You do not currently have any OCR source text saved for this book.
                    </DialogContentText>
                    <br></br>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to use RoboEdit's OCR feature?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined' onClick={handleOcrClose}>
                        No, I have my own OCR source text
                    </Button>
                    <Button color='success' variant='outlined' onClick={handleOcrConfirmed} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={characterFormOpen} onClose={handleCharacterFormClose}>
                <DialogTitle>Create New Character</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter character information. This is used to perform auto find/replace for OCR scannos.
                    </DialogContentText>
                    <br></br>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <div>
                                <TextField
                                    onChange={handleFirstNameChange}
                                    autoFocus
                                    margin="dense"
                                    id="firstname"
                                    type="text"
                                    helperText="First name"
                                    variant="standard"
                                />
                                <TextField
                                    onChange={handleNicknameChange}
                                    autoFocus
                                    margin="dense"
                                    id="nickname"
                                    type="text"
                                    fullWidth
                                    helperText="Nickname"
                                    variant="standard"
                                />
                            </div>
                        </FormControl>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <div>
                                <TextField
                                    onChange={handleMiddleNameChange}
                                    autoFocus
                                    margin="dense"
                                    id="middlename"
                                    type="text"
                                    helperText="Middle Name / Patronymic"
                                    variant="standard"
                                />
                                <TextField
                                    onChange={handleLastNameChange}
                                    autoFocus
                                    margin="dense"
                                    id="lastname"
                                    type="text"
                                    fullWidth
                                    helperText="Last Name"
                                    variant="standard"
                                />
                            </div>
                        </FormControl>
                    </Box>
                    <Box>
                        <h3>
                            {renderName(firstname, nickname, middlename, lastname)}
                        </h3>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="info" onClick={handleCharacterFormClose}>Cancel</Button>
                    <Button color="success" onClick={() => createNewCharacter(characters, firstname, nickname, middlename, lastname)}>Create</Button>
                </DialogActions>
            </Dialog>
            <h3>Table of Contents</h3>
            {renderFrontMatter()}
            {renderFreeChapters()}
            {renderBookSections()}
            {renderBackMatter()}
            {
                renderNewButton()
            }
            <div className="DeleteBtn">
                <Button className='DeleteBtn' onClick={() => exportDocx(bookLayout)} color="info" variant="contained" endIcon={<ExportIcon />}>
                    Export to .docx
                </Button>
                <br></br>
            </div>
            <hr></hr>
            <div className="Characters">
                <h3>Characters</h3>
                <p>To help RoboEdit accurately proofread, you can add standardized character names that will be included in the prompt.</p>
                <br></br>
                <Button className='CharacterBtn' onClick={() => createCharacter()} color="secondary" variant="contained" endIcon={<PersonIcon />}>
                    New Character
                </Button>
                <div className="CharactersList">

                    {characters && characters.map(element => {
                        if ((element.firstname !== undefined && element.nickname !== undefined && element.lastname !== undefined && element.middlename !== undefined))
                            return <div className="CharacterChip"><Chip variant="outlined" onDelete={() => deleteCharacter(element.id)} label={renderName(element.firstname, element.nickname, element.middlename, element.lastname)} /></div>
                    }
                    )}
                </div>
            </div>
            <hr></hr>
            <div className="DeleteBtn">
                <Button className='DeleteBtn' onClick={() => openDeleteForm()} color="error" variant="contained" endIcon={<DeleteIcon />}>
                    Delete Book
                </Button><br></br>
            </div>
            <div className="FabBtnBox">
                <Tooltip title='Create new book element'>
                    <Fab
                        color='success'
                        onClick={() => createElement()}
                    ><AddIcon /></Fab>
                </Tooltip>
                {/* <Button color="success" variant="contained" endIcon={<SaveIcon />}
                            onClick={() => saveAndUpload(targetParagraphs, footnotes, paragraphStyles)}>Save Element</Button> */}
            </div>
            {/* {
                sections.map(section => {
                    console.log("TOP", section);
                    let chapterArr = bookObj["sections"][String(section)];
                    console.log(chapterArr)
                    return (<div>
                        <p><b>Section {section}</b></p>
                        {chapterArr.map(element => {
                            // console.log("BOTTOM", element)
                            return (<Link to={'../editor'} state={{
                                book: book,
                                title: book.title,
                                content: section + '_' + element.chapter,
                                chapter: element.chapter,
                                chaptername: element.chaptername,
                                section: section
                            }}>
                                <p>{element.chaptername}</p>
                            </Link>)
                        })}
                    </div>

                    )
                })
            } */}


            {/* {
                chapters?.map((chapter, index) => {
                    let sections = renderSections(chapters, index);
                    console.log(sections)
                    {
                        Object.entries(sections).map(([key, val], i) => {
                            console.log(key, val)
                            return (<p key={i}>
                                {key}: {val}
                            </p>)
                        })
                    }
                }
                )
            } */}

            <footer>

            </footer>
        </div>
    )
}

export default Contents;